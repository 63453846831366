<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'
               v-loading="loading" :close-on-click-modal='false' width='1100px'>
      <el-card class="box-card" style="width: 100%;text-align: center;margin-bottom: 10px">
        <el-form :model='formData' size='mini' label-width='140px' label-position='right'>
          <el-row>
            <el-col :span="12">
              <el-form-item label='产品名称'><span style="float: left">{{ formData.title }}</span></el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label='类目'><span style="float: left">{{ formData.category }}</span></el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label='规格'>
                <el-input placeholder='规格' v-model='formData.spec_name' maxlength='100' show-word-limit
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label='色号'>
                <el-input placeholder='色号' v-model='formData.spec_color' maxlength='100' show-word-limit
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label='可播平台' prop='platform_code'>
                <PlatformSelect @saved='handleSelectPlatform' :range-all='true'
                                placeholder='请选择可播平台'
                                :default-code.sync='formData.platform_code'/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label='日常价'>
                <el-input placeholder='' v-model='formData.price' maxlength='200' show-word-limit clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label='直播价'>
                <el-input placeholder='' v-model='formData.lb_price' maxlength='200' show-word-limit
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label='赠品'>
                <el-input placeholder='' v-model='formData.gift' maxlength='200' show-word-limit clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label='费用佣金'>
                <el-input placeholder='' v-model='formData.fee_commission' maxlength='200' show-word-limit
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label='保价情况'>
                <el-input placeholder='保价情况' v-model='formData.support_situation' maxlength='200' show-word-limit
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label='是否大促同机制'>
                <el-input placeholder='是否大促同机制（如不是请标注大促机制）' v-model='formData.promo_remark' maxlength='200' show-word-limit
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label='是否统一机制'>
                <el-input placeholder='是否统一机制（如有特殊请备注）' v-model='formData.other_activity' maxlength='500' show-word-limit
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label='品牌支持'>
            <el-row>
              <el-col :span='16'>
                <el-input placeholder='可任意输入内容' v-model='formData.brand_support' maxlength='50' show-word-limit
                          clearable></el-input>
              </el-col>
              <el-col :span='8'>
                <div class='demo-tag'>
                  <el-tag @click='choiceOption(option,"brand_support")' type='primary' size='small' effect='plain'
                          v-for='(option,key) in optionMap.brand_support' :key='key' style='margin:0 5px'>
                    <el-link type='primary'>{{ option }}</el-link>
                  </el-tag>
                </div>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label='负责人' prop='leader_id'>
            <div style='display: inline-block;float: left'>
              <el-link title='产品负责人' @click='editLeader=!editLeader' v-if='formData.leader_id'
                       style='margin-right: 10px'>
                {{ formData.leader_name }}
              </el-link>
              <UserSelect v-if='editLeader||!formData.leader_id' :keyword='formData.leader_name'
                          @selected='handleUserSelect'/>

              <el-button title='修改负责人' type='text' icon='el-icon-edit-outline' @click='editLeader=!editLeader'
                         v-if='formData.leader_id'></el-button>
            </div>
          </el-form-item>
          <!--          <el-form-item label='负责人'><span>{{ formData.leader_name || '' }}</span></el-form-item>-->
          <el-form-item label='备注信息'>
            <el-input placeholder='' v-model='formData.remark' maxlength='255' show-word-limit clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size='small' @click.stop.native='close'>取消</el-button>
            <el-button size='small' type='primary' @click.stop.native @click='saveRow'>保存</el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <el-row>
        <el-col :span="12">
          <el-button size="big" :disabled="isActive===0" icon='el-icon-arrow-left' @click="previousOne">上一项</el-button>
        </el-col>
        <el-col :span="12">
          <el-button style="float: right" size="big" @click="nextOne" :disabled="isActive===count-1">下一项
            <i class='el-icon-arrow-right'></i></el-button>
        </el-col>
      </el-row>

    </el-dialog>
  </div>
</template>

<script>
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import PlatformSelect from '@/pages/lb/components/PlatformSelect'
import UserSelect from '@/pages/lb/components/UserSelect'

export default {
  name: 'EditLbProduct',
  components: { CategoryCascader, PlatformSelect, UserSelect },
  props: {
    ids: {
      type: Array
    }
  },

  data() {
    return {
      dialogTitle: '修改产品机制',
      formData: {},
      optionMap: {
        brand_support: ['福袋', '秒杀'],
        sample_info: ['是', '否']
      },
      selectedModeList: [],
      // cascaderProps: {
      //   value: 'id', label: 'display_name', checkStrictly: true, multiple: false
      // },
      fieldRange: ['platform_code', 'platform_name', 'spec_name', 'spec_color', 'price', 'lb_price', 'gift', 'other_activity', 'support_situation', 'fee_commission', 'brand_support', 'stock_info', 'sample_info', 'reason', 'remark', 'promo_remark']
      ,
      isActive: 0,
      count: 0,
      loading: false,
      leaderVisible: false,
      editLeader: false

    }
  },

  methods: {
    onOpen() {
      //重置表单
      this.reset()
      //加载信息
      if (this.ids) {
        this.getInfo()
        this.isActive = 0
        this.count = this.ids.length
      }
    },
    onClose() {
      this.$emit('close')
      this.isActive = 0
    },
    close() {
      this.showNotify = false
      this.$emit('update:visible', false)
    },
    reset() {
      let form = {}
      this.fieldRange.forEach((filed) => {
        form[filed] = null
      })
      this.formData = { ...form }
    },
    //快捷选择
    choiceOption(option, field) {
      this.formData[field] = option
    },
    cascaderModeChange(v) {
      this.formData.mode = null
      if (v.length) {
        this.formData.mode = v[(v.length - 1)]
      }
    },
    async getInfo() {
      this.loading = true
      let { info } = await this.$api.getLbScheduleProductInfo(this.ids[this.isActive])
      if (info) {
        this.selectedModeList = [info.mode]
        this.formData = { ...info }
        this.loading = false
      }
    },
    async saveRow() {
      let id = await this.$api.saveLbScheduleProductInfo(this.formData)
      if (id) {
        this.$message.success('保存成功')
      }
    },
    handleSelectPlatform(val) {
      this.formData.platform_code = val ? val.code : []
      this.formData.platform_name = val ? val.name : []
    },
    nextOne() {
      this.isActive++
      this.getInfo()
    },
    previousOne() {
      this.isActive--
      this.getInfo()
    },
    // 处理搜索结果的选中
    handleUserSelect(item) {
      this.formData.leader_id = item.value || ''
      this.formData.leader_name = item.label || ''
      this.editLeader = false
    }
  }
}
</script>

<style scoped>
.demo-tag {
  border: #909399 1px dashed;
  margin-left: 5px;
  border-radius: 5px;
}

.demo-tag::before {
  content: "参考：";
}
</style>
