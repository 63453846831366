<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle' :close-on-click-modal="false"  fullscreen>
      <div>
        <el-form :model='searchCondition' inline size='mini'>

          <!--          <el-form-item label='负责人'>-->
          <!--            <LeaderSearch :keyword.sync='searchConditionLeaderName' @selected='selectedLeader' />-->
          <!--          </el-form-item>-->
          <el-form-item label='负责人'>
            <template slot='label'>
              <el-link @click='setMine'>负责人</el-link>
            </template>
            <el-input placeholder='负责人名模糊搜索' @clear='handleQuery' v-model='searchCondition.leader_name'
                      clearable />
          </el-form-item>
          <el-form-item label='类目'>
            <CategoryCascader :identify='`product`' @cascaderChange='cascaderChange' />
          </el-form-item>
          <el-form-item label='商品名称'>
            <el-input placeholder='商品名称' @clear='handleQuery' v-model='searchCondition.title'
                      clearable />
          </el-form-item>
          <el-form-item label='品牌'>
            <template slot='label'>
              <el-link @click.stop.native='getBrand(true)'><i
                :class='[loadingBrandStatus?"el-icon-loading":"el-icon-refresh"]'></i>品牌
              </el-link>
            </template>
            <el-select clearable v-model='searchCondition.brand_id'
                       filterable
                       style='width: 100%' @focus='getBrand' @change='handleQuery' placeholder='请选择品牌'>
              <el-option
                v-for='item in brandOptions'
                :key='item.id'
                :label='item.name'
                :value='item.id'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
          </el-form-item>
        </el-form>
        <el-row class='mb8'>
          <el-col :span='1.5'>
            <el-button size='mini' type='primary'
                       icon='el-icon-plus' :disabled='multiple' @click='handleAdd'>添加到《直播选品表》
            </el-button>
          </el-col>
        </el-row>
        <!--        选品库-->
        <div class='default-table' v-loading='loading' element-loading-text='加载中……'
             element-loading-spinner='el-icon-loading'>
          <el-table :data='dataList' ref='productMultipleTable' row-key='id' @selection-change='handleSelectRows'
                    highlight-current-row border>
            <el-table-column type='selection' :reserve-selection='true' width='55'
                             :selectable='selectable'></el-table-column>
            <el-table-column type='index' label='序号' width='55' align='center'></el-table-column>
            <el-table-column label='商品名称' prop='title' min-width='260' header-align='center' align='left'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <!--                <el-tag :type="`${row.is_ack==='Y'?'success':'danger'}`" effect='plain' style='margin-right: 2px'-->
                <!--                        v-if='row.lb_product_id'>-->
                <!--                  {{ row.is_ack === 'Y' ? '确认' : '初选' }}-->
                <!--                </el-tag>-->
                <span :title='`${row.id}`'>{{ row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column label='产品图' prop='cover' width='70' align='center'>
              <template slot-scope='{row}'>
                <product-image :image-url='row.cover_url' />
              </template>
            </el-table-column>
            <el-table-column label='品牌' prop='brand_name' width='100' align='center'></el-table-column>
            <el-table-column label='类目' prop='category_id' min-width='80' align='center'>
              <template slot-scope='{row}'>
                <span>{{ row.category || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='规格' prop='spec_name' align='center' min-width='100'
                             show-overflow-tooltip></el-table-column>
            <el-table-column label='合作方式' prop='mode' align='center' min-width='100'>
              <template slot-scope='{row}'>
                <span>{{ row.mode_alias || '' }}</span>
              </template>
            </el-table-column>
            <!--        <el-table-column label="日常价/直播价" align="center" min-width="100">-->
            <!--          <template slot-scope="{row}">-->
            <!--            <span>{{ row.price || '' }} 元 / {{ row.lb_price || '' }} 元</span>-->
            <!--          </template>-->
            <!--        </el-table-column>-->
            <el-table-column label='日常价(元)' align='center' min-width='140'>
              <template slot-scope='{row}'>
                <span>{{ row.price || '/' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='直播价(元)' align='center' min-width='140'>
              <template slot-scope='{row}'>
                <span>{{ row.lb_price || '/' }} </span>
              </template>
            </el-table-column>
            <el-table-column label='赠品' align='center' min-width='120' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.gift || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column label='独家机制' prop='other_activity' align='center' min-width='120' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.other_activity || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column label='坑位费(元)' prop='ad_space_fee' align='center' min-width='110'>
              <template slot-scope='{row}'>
                <span>{{ row.ad_space_fee || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='线上/线下佣金' align='center' min-width='120'>
              <template slot-scope='{row}'>
                <span>{{ row.online_cmn || '' }} % / {{ row.offline_cmn || '' }} %</span>
              </template>
            </el-table-column>
            <el-table-column label='负责人' prop='leader' align='center' min-width='100'>
              <template slot-scope='{row}'>
                <span>{{ row.leader_name || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='备注信息' prop='remark' align='center' min-width='140' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.remark || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='店铺名称' prop='company_name' align='center' min-width='140' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.company_name || '' }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div style='text-align: right'>
            <pagination v-show='total>0'
                        :total.sync='total'
                        :page.sync='pageData.current_page'
                        :limit.sync='pageData.page_size'
                        @pagination='getList' />
          </div>
        </div>
        <div>
          <el-row class='mb8' :gutter='15'>
            <el-col :span='1.5'>
              <el-button size='mini' type='primary'
                         icon='el-icon-plus' :disabled='multiple' @click='handleAdd'>添加到《直播选品表》
              </el-button>
            </el-col>
            <el-col :span='1.5'>
              <el-button size='mini' type='primary'
                         icon='el-icon-refresh' @click='getList' plain>刷新列表
              </el-button>
            </el-col>
            <el-col :span='2'>
              <div style='display: inline-block;margin-left: 10px'>
                <div class='selected-total'>已选：
                  <span style='color: #ff3176;font-size: 1.4em;font-style: italic;'>{{ ids.length }}</span> 个
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import ProductImage from '@/pages/lb/product/ProductImage'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import { mapGetters } from 'vuex'
import LeaderSearch from '@/pages/lb/components/LeaderSearch'

export default {
  name: 'DialogSelectProduct',
  components: { LeaderSearch, CategoryCascader, ProductImage },
  props: {
    lbId: {
      type: [Number, String]
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  data() {
    return {
      loading: false,
      dataList: [],
      total: 0,
      pageData: { page_size: 10, current_page: 1 },
      searchCondition: { category_ids: null, title: null, leader_name: null, leader_id: null, brand_id: null },
      searchConditionLeaderName: null,
      dialogTitle: '直播定品-添加商品',
      loadingBrandStatus: false,
      brandOptions: [],
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true
    }
  },
  methods: {
    onOpen() {
      this.dialogTitle = `直播定品-添加商品(ID:${this.lbId})`
      this.searchCondition = { category_ids: null, title: null, leader_name: null, leader_id: null, brand_id: null }
      // this.searchCondition.leader_name = this.userInfo.nickname
      this.getList()
    },
    onClose() {
    },
    close() {
      this.$emit('update:visible', false)
    },
    selectedLeader(item) {
      // console.log(item)
      this.searchCondition.leader_id = item.leader_id
      this.handleQuery()
    },
    // 级联菜单组件change事件
    cascaderChange(v) {

      if (v.length > 0) {
        this.searchCondition.category_id = v[(v.length - 1)]
        this.searchCondition.category_ids = v
      } else {
        this.searchCondition.category_id = null
        this.searchCondition.category_ids = null
      }

      this.handleQuery()
    },
    async getBrand(force) {
      this.loadingBrandStatus = true
      if (this.brandOptions.length === 0 || force) {
        // let data = await this.$api.selectCustomerBrands({ customer_id: this.formData.customer_id })
        let { options } = await this.$api.getBrandOptions()
        this.brandOptions = options
        this.loadingBrandStatus = false
      }
    },
    setSelectBrand(val) {
      let option = this.brandOptions.find(value => value.id === val)
      this.searchCondition.brand_name = option ? option.name : ''
    },

    selectable(row) {
      return !row.lb_product_id
    },
    setMine() {
      this.searchCondition.leader_name = this.userInfo.nickname
      this.handleQuery()
    },
    handleQuery() {
      this.dataList = []
      this.pageData.current_page = 1
      this.getList()
    },
    handleSearchCondition() {
      //判断有没有保存全部的权限
      // if (!(this.userPermissions.indexOf('product_selection_preview') > -1)) {
      //   this.searchCondition.leader_name = this.leader.leader_name
      //   this.searchCondition.leader_id = this.leader.leader_id
      // }
      let cond = {}
      // if (this.searchCondition.category_id) {
      //   cond['category_id'] = this.searchCondition.category_id
      // }
      if (this.searchCondition.category_ids) {
        cond['category_ids'] = this.searchCondition.category_ids
      }
      if (this.searchCondition.title) {
        cond['title'] = this.searchCondition.title
      }
      if (this.searchCondition.leader_id) {
        cond['leader_id'] = this.searchCondition.leader_id
      }
      if (this.searchCondition.leader_name) {
        cond['leader_name'] = this.searchCondition.leader_name
      }
      if (this.searchCondition.brand_id) {
        cond['brand_id'] = this.searchCondition.brand_id
      }

      return cond
    },
    async getList() {
      this.loading = true
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, { lb_id: this.lbId }, this.pageData)
      let { list, pages } = await this.$api.getProductListExclude(searchCondition)
      this.$nextTick(() => {
        this.dataList = list || []
        this.pageData.current_page = pages.current_page || 1
        this.pageData.page_size = pages.page_size || 1
        this.total = pages.total
        this.loading = false
        //触发选中效果
        // this.toggleSelection()
      })
    },
    handleSelectRows(selection) {
      // this.selectedList = selection || []
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    async handleAdd() {
      let postData = {
        lb_id: this.lbId,
        product_ids: this.ids
      }
      // console.log(postData)
      await this.$api.appendLbScheduleProducts(postData)
      this.close()
    }
  }
}
</script>

<style scoped>

</style>
