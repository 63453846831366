<template>
  <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose'
             :close-on-click-modal='false' width='90%' :title='dialogTitle'>

    <div v-if='rowId' style='width: 100%;text-align: center;font-size: 24px;font-weight: bold;margin-bottom: 8px'>
      {{ currentTitle }}
    </div>

    <el-row :gutter='15' style='min-height: 420px'>
      <el-col :span='6'>
        <div class='default-table'>

          <el-table :data='historicalList'
                    height='420'
                    @selection-change='handleSelectionChange'
                    :columns='historicalColumns'
                    @row-click='dbRowClick'
                    highlight-current-row
                    :border='true'>
            <el-table-column type='index' label='序号' width='55' align='center'></el-table-column>
            <el-table-column label='排期名称' min-width='255' header-align='center' align='left'>
              <template slot-scope='{row}'>
                <!--                <el-tag type='info' effect='plain' v-if='row.id==lbId'>【当前】</el-tag>-->
                <!--                <el-tag type='success' effect='plain' v-else>【其他】</el-tag>-->
                {{ $utils.parseTime(row.start_at, '{y}年{m}月{d}日') }}
                【{{ row.nickname }}】{{ row.platform_name }}
              </template>
            </el-table-column>

          </el-table>
        </div>
      </el-col>
      <el-col :span='18' v-if='rowId'>
        <el-form :model='searchCondition' inline>
          <el-form-item v-if="diagType === 'selection'">
            <el-select clearable v-model='searchCondition.state' placeholder='请选择上播状态'
                       @change='getList'>
              <el-option
                v-for='item in stateOptions'
                :key='item.value'
                :label='item.label'
                :value='item.value'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label=''>
            <el-input v-model='searchCondition.title' placeholder='产品名称' clearable
                      @change='getList'></el-input>
          </el-form-item>
          <el-form-item>
            <CategoryCascader  :identify='`product`'
                              @cascaderChange='cascaderChange' />
          </el-form-item>
          <el-form-item>
            <el-select @change='getList' clearable v-model='searchCondition.leader_id' placeholder='请选择负责人'>
              <el-option
                  v-for='item in leaders'
                  :key='item.leader_id+item.leader_name'
                  :label='item.leader_name'
                  :value='item.leader_id'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' icon='el-icon-search' @click='getList'
                       size='mini'>搜索
            </el-button>
          </el-form-item>
        </el-form>

        <div class='default-table'>
          <m-table :showIndex='false' :tableData.sync='dataList'
                   @selection-change='handleSelectionChange'
                   :columns='productColumns'
                   row-key='id'
                   :border='true'
                   max-height='600'

          >
            <el-table-column type='selection' width='40'
                             slot='first-column'></el-table-column>
            <el-table-column type='index' width='55'
                             slot='first-column' label='序号' align='center'></el-table-column>
          </m-table>
        </div>
      </el-col>
    </el-row>
    <!--      <el-row>-->
    <!--        <el-col :span='24' style='text-align: right'>-->
    <!--          <Pagination :limit.sync='pagingProduct.page_size'-->
    <!--                      :page.sync='pagingProduct.current_page'-->
    <!--                      :total='pagingProduct.total'-->
    <!--                      @pagination='getList' />-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <el-row style='margin-top: 20px'>
      <el-col :span='24'>
        <div style='text-align: center'>
          <el-button size='big' @click='close'>取消</el-button>
          <el-tooltip class='item' effect='dark' :content='contentText' placement='bottom'>
            <el-button @click='saveBtn' size='big' type='primary' icon='el-icon-plus'>
              添加数据{{ selectData.length != 0 ? `(${selectData.length}条)` : '' }}
            </el-button>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import CategoryCascader from '@/pages/category/components/CategoryCascader'

export default {
  name: 'HistoricalSchedule',
  components: { CategoryCascader },
  data() {
    return {
      dataList: [],
      historicalList: [],
      info: {},
      historicalColumns: [
        {
          title: '历史排期',
          value: 'contract_progress',
          width: 120
        }
      ],
      pagingProduct: {
        current_page: 1,
        page_size: 10,
        total: 0
      },
      productColumns: [
        {
          title: '产品图片',
          value: 'cover_url',
          type: 'image',
          width: 50
        },
        {
          title: '产品名称',
          value: 'title',
          field: 'title',
          header_align: 'center',
          align: 'left',
          width: 140,
          sortable: 'custom'
        },

        // {
        //   title: '产品ID',
        //   value: 'product_id',
        //   width: 60
        // },
        {
          title: '类目',
          value: 'category',
          width: 120
        },
        {
          title: '规格',
          value: 'spec_name',
          width: 120
        }, {
          title: '日常价',
          value: 'price',
          width: 80
        },
        {
          title: '直播价',
          value: 'lb_price',
          width: 80
        },
        {
          title: '费用佣金',
          value: 'fee_commission',
          width: 120
        },
        {
          title: '大促机制',
          value: 'promo_remark',
          width: 120
        },
        {
          title: '日常机制/保价情况',
          value: 'support_situation',
          width: 120
        },
        {
          title: '负责人',
          value: 'leader_name',
          width: 80
        }
      ],
      selectData: [],
      currentRow: {},
      diagType: '',
      searchCondition: {},
      leaders: [],
      stateOptions: [
        { label: '上播', value: 1, color_value: '#909399', class_name: 'status-new' },
        { label: '不上播', value: 2, color_value: '#67C23A', class_name: 'status-launch' },
        { label: '红人试用', value: 3, color_value: '#409EFF', class_name: 'status-original' },
        { label: '团队试用', value: 4, color_value: '#E6A23C', class_name: 'status-ack' }
      ],
      filterData: [],
      rowId: 0
    }
  },
  props: {
    lbId: {
      type: [Number, String]
    },
    artistId: {
      type: String
    },
    historicalType: {
      type: String
    }
  },
  computed: {
    contentText() {
      let returnText = '请选择数据'
      if (this.selectData.length != 0) {
        let data = this.selectData.map((i) => {
          return i.title || ''
        })
        returnText = String(data)
      }
      return returnText
    },
    dialogTitle() {
      return `【${this.info.nickname}】的历史${this.diagType === 'selection' ? '选品会' : '直播'}排期`
    },
    currentTitle() {
      return this.currentRow.id ? `${this.$utils.parseTime(this.currentRow.start_at, '{y}年{m}月{d}日')}【${this.currentRow.nickname}】${this.currentRow.platform_name} 的【${this.diagType === 'selection' ? '红人选品' : '直播定品'}】列表` : ''
    }
  },
  methods: {
    onOpen() {
      this.historicalList = []
      this.dataList = []
      this.selectData = []
      this.currentRow = {}
      this.diagType = this.historicalType
      // //初始化当前排期为筛选排期，没有意义
      // this.rowId = this.lbId
      this.rowId = null
      this.getInfo()
    },

    onClose() {
      this.historicalList = []
      this.dataList = []
      this.selectData = []
      this.currentRow = {}
      this.filterData = []
      // this.$emit('')
    },
    close() {
      this.$emit('update:visible', false)
    },
    // eslint-disable-next-line no-unused-vars
    dbRowClick(row, column, event) {
      this.pagingProduct.current_page = 1
      this.currentRow = { ...row }
      this.rowId = row.id
      this.searchCondition = {}
      this.getList()

    },
    // close() {
    //   this.$emit('update:historicalDialog', false)
    //   this.$emit('close')
    // },
    async saveBtn() {
      if (this.selectData.length === 0) {
        this.$notify.error('请选择添加数据')
      } else {
        //这里保存数据
        await this.saveSelections()
      }
    },
    async saveSelections() {
      let ids = this.selectData.map((item) => {
        return item.id
      })
      let postData = {
        lb_id: this.lbId,
        sids: ids
      }
      await this.saveProducts(postData)
    },
    async saveProducts(postData) {
      let info = await this.$api.saveLbScheduleProducts(postData)
      if (info) {
        this.$notify.success('保存成功')
        this.close()
      }
    },
    async getHistoryLbList() {
      //暂时不排除自身
      this.historicalList = []
      if (this.info && this.info.artist_id) {
        let params = {
          artist_id: this.info.artist_id,
          type: 2,
          order: { start_at: 'desc' }
        }
        params.type = this.diagType === 'selection' ? 1 : 2
        let { list } = await this.$api.getLbScheduleList(params)
        this.$nextTick(() => {
          this.historicalList = list.map((i) => {
            return i.id != this.lbId ? i : false
          }).filter(Boolean)
        })
      }
    },
    async getInfo() {
      let { info } = await this.$api.getLbScheduleInfo(this.lbId)
      this.info = info

      await this.getHistoryLbList()
    },
    async getList() {
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      // let searchCondition = { ...this.pagingProduct, ...{ lb_id: id } }
      // this.diagType === 'selection' ? searchCondition.state = 1 : ''
      //获取产品列表
      let { list } = await this.$api.getLbScheduleAckProducts(searchCondition)
      this.dataList = list || []
      this.getLeader()

    },
    getLeader() {
      if (this.dataList.length != 0) {
        let leaders = this.dataList.map((i) => {
          return { leader_name: i.leader_name, leader_id: i.leader_id }
        })
        this.leaders = [...new Set(leaders.map(JSON.stringify))].map(JSON.parse)
      } else {
        this.leaders = []
      }

    },
    handleSelectionChange(val) {
      this.selectData = val
    },

    // 级联菜单组件change事件
    cascaderChange(v) {
      this.searchCondition.category_id = null
      this.searchCondition.category_ids = null
      if (v && v.length) {
        this.searchCondition.category_id = v[(v.length - 1)]
        this.searchCondition.category_ids = v
      }
      this.getList()
    },
    handleSearchCondition() {
      let cond = {}
      if (this.searchCondition.category_ids) {
        cond['category_ids'] = this.searchCondition.category_ids
      }
      if (this.searchCondition.title) {
        cond['title'] = this.searchCondition.title
      }
      if (this.searchCondition.leader_id) {
        cond['leader_id'] = this.searchCondition.leader_id
      }
      if (this.searchCondition.state) {
        cond['state'] = this.searchCondition.state
      }
      if (this.searchCondition.confirm_status) {
        cond['confirm_status'] = this.searchCondition.confirm_status
      }
      cond['lb_id'] = this.rowId
      return cond
    }


  }
}
</script>

<style scoped>

</style>
