<template>
  <div>
    <div
        v-loading='loading'
        element-loading-text='玩命加载中……'
        element-loading-spinner='el-icon-loading'>
      <ux-grid
          ref='elTable'
          :data='tableData'
          :height="height"
          v-bind='$attrs'
          use-virtual
          :big-data-checkbox="true"
          row-key
          show-header-overflow="ellipsis"
          show-overflow="tooltip"
          :inverse-current-row="true"
          v-on='$listeners'>

        <ux-table-column type="index" v-if='showIndex' :title="'序号'" width="100" :align="'center'"/>
        <template v-for='(v) in tableColumns' class="vbox">
          <ux-table-column :key="v.label" :title="v.title">
            <template slot-scope='{row}'>
              <template v-for="(item,index) in newColumns">
                <template v-if="item.belong===v.type">
                  <div class="items" :key="index">
                    <div class="imgBox" v-if="item.prop=='cover_url'&&!item.hide">
                      <el-image :preview-src-list="[row[item.prop]]" :src="row[item.prop]"></el-image>
                    </div>
                    <div class="info" v-else-if="!item.hide">
                      <span>{{ item.label }}  </span>
                      <b>
                        {{ row[item.prop] || '/' }}</b>
                    </div>
                  </div>
                </template>
              </template>
            </template>
          </ux-table-column>
        </template>

        <!--        <slot name='handle-column'></slot>-->

        <!--        <slot/>-->
      </ux-grid>
    </div>
    <div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'MSetTable',
  props: {
    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => []
    },
    loading: {
      default: false
    },
    showIndex: {
      default: false
    },
    offset: {
      default: 0
    },
    height: {
      type: Number,
      default: 800
    }
  },
  data() {
    return {
      tableColumns: [
        {title: '基本信息相关', type: 'info'},
        {title: '直播信息相关', type: 'live'},
        {title: '价格/赠品相关', type: 'content'}
      ],
      newColumns: []

    }
  },
  watch: {
    columns() {
      this.setNewColumns()
    }
  },
  methods: {
    clearSelection() {
      this.$refs.elTable.clearSelection()
    },
    setNewColumns() {
      this.newColumns = []
      let infoData = ['cover_url', 'category', 'spec_name', 'spec_color', 'platform_names', 'created_at', 'updated_at'
        , 'leader_name', 'contract_progress', 'brand_support']
      let liveData = ['opt_remark', 'product_updated_at', '_confirm_status']
      let contentData = ['price', 'lb_price', 'gift', 'fee_commission', 'other_activity', 'support_situation', 'promo_remark'
        ,]
      this.columns.forEach((item) => {
        if (infoData.includes(item.prop)) {
          item.belong = 'info'
        } else if (liveData.includes(item.prop)) {
          item.belong = 'live'
        } else if (contentData.includes(item.prop)) {
          item.belong = 'content'
        }
        this.newColumns.push(item)
      })

    },

  },
  mounted() {
    this.setNewColumns()
  }
}
</script>


<style lang="scss" scoped>
::v-deep .elx-cell {
  white-space: initial !important;
  max-height: none !important;
  /*border: 1px solid red;*/

}

::v-deep .el-image {
  display: block;
  height: 80px;
  object-fit: contain;

  > img {
    //height: 80px;
    object-fit: contain;
  }

}

.items {
  display: inline-block;
  float: left;
  //border: 1px solid red;
}


.imgBox {
  width: 80px;
  min-height: 130px;
  height: 100%;
  margin-right: 10px;
  margin-top: 10px;
}

.info {
  min-width: 180px;
  border: 1px dotted #ebeef5;
  min-height: 30px;
  line-height: 30px;
  margin: 5px;
  padding-right: 2px;
  background-color: #fff;


  > span {
    color: #909399;
    background-color: #fafafa;
    padding: 2px;
    text-align: center;
    height: 100%;
    display: inline-block;
    margin-right: 6px;
    user-select: none;
    font-size: 12px;

  }

  > b {
    text-align: left;
    color: #606266;
    font-weight: 500;
    padding-right: 10px;
    font-size: 12px;
  }
}

::v-deep .elx-body--row {
  background-color: white !important;
}
</style>

